import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@app/shared/base/components/base-component';
import { ForbiddenService } from '@app/shared/services/forbidden.service';
import {Observable} from "rxjs";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import { map, shareReplay } from "rxjs/operators";
import { LayoutService } from "@app/core/layout.service";

const nonClosableSidebarMediaQuery = '(min-width: 960px)';

@Component({
  selector: 'hypecast-admin-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss']
})
export class BaseLayoutComponent extends BaseComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );
  readonly isSidebarNonClosable$ = this.breakpointObserver.observe(nonClosableSidebarMediaQuery)
    .pipe(map(({ matches }) => matches),);
  readonly vh100ContentSection$: Observable<boolean>;

  isForbidden: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private forbiddenService: ForbiddenService,
    private layoutService: LayoutService,
  ) {
    super();

    this.vh100ContentSection$ = this.layoutService.vh100ContentSection$;
  }

  ngOnInit() {
    super.addSubscription(
      this.forbiddenService
        .isForbidden$
        .subscribe((isForbidden: boolean) => {
          this.isForbidden = isForbidden;
        })
    );
  }

}
