<ul class="-ml-5 -mr-5">
  <li>
    <hypecast-admin-navigation-link displayName="Dashboard" icon="home" link="dashboard">
    </hypecast-admin-navigation-link>
  </li>
  <li>
    <hypecast-admin-navigation-link displayName="Podcasts" icon="microphone" link="podcasts">
    </hypecast-admin-navigation-link>
  </li>
  <li *ngIf="showMeetingEntry()">
    <hypecast-admin-navigation-link displayName="Studios" icon="record" link="studios">
    </hypecast-admin-navigation-link>
  </li>
  <li *ngIf="showAudioAiEntry()">
    <hypecast-admin-navigation-link displayName="HypecastAI" icon="sparkles" link="audio-ai">
    </hypecast-admin-navigation-link>
  </li>
  <li>
    <hypecast-admin-navigation-link displayName="Analytics" icon="line_chart" link="analytics">
    </hypecast-admin-navigation-link>
  </li>
  <li>
    <hypecast-admin-navigation-link displayName="Groups" icon="group" link="groups">
    </hypecast-admin-navigation-link>
  </li>
  <li>
    <hypecast-admin-navigation-link displayName="Users" icon="person" link="users">
    </hypecast-admin-navigation-link>
  </li>
  <li>
    <hypecast-admin-navigation-link displayName="Sound Library" externalLink="true" icon="library_music"
                                    link="https://hypecast.one/audio-library-ei3q7qaj1xrcknag9tft8htb6da2u5lu">
    </hypecast-admin-navigation-link>
  </li>
  <!-- DIVIDER -->
  <li>
    <div class="m-3 ml-6 w-32 bg-secondary-light-blue" style="height: 1px;"></div>
  </li>
  <!-- /divider -->
  <li>
    <hypecast-admin-navigation-link displayName="New podcast" icon="microphone_plus" link="podcasts/create">
    </hypecast-admin-navigation-link>
  </li>
  <li *ngIf="isCompanyAdministrator()">
    <hypecast-admin-navigation-link displayName="Invite user" icon="person_plus" link="users/create">
    </hypecast-admin-navigation-link>
  </li>
  <li>
    <hypecast-admin-navigation-link displayName="New group" icon="group_plus" link="groups/create">
    </hypecast-admin-navigation-link>
  </li>
  <li>
    <hypecast-admin-navigation-link displayName="Settings" icon="cog" link="settings/edit">
    </hypecast-admin-navigation-link>
  </li>
</ul>
