import { Component } from "@angular/core";
import { environment } from "@env/environment";
import { ITenantService } from "@app/shared/services/itenant.service";
import { HypecastAuthenticationService } from "@app/shared/authentication/services/authentication.service";

/**
 * This component is responsible for the display of the basic navigation that is visible
 * on all authenticated pages.
 *
 * @export
 * @class NavigationComponent
 * @implements {OnInit}
 */
@Component({
  selector: "hypecast-admin-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent {
  tenantsWithoutMeetings: string[] = [];
  tenantsWithAudioAi: string[] = ["hype1000", "samsung", "cewe", "sos-kinderdoerfer-at", "cvs", "ebay"];

  constructor(private tenantService: ITenantService, private authenticationService: HypecastAuthenticationService) {}

  showMeetingEntry() {
    // always show Meeting entry on DEV and QA
    if (environment.production === false) {
      return true;
    }
    // only show Meeting entry if tenant is not in blacklist
    return !this.tenantsWithoutMeetings.includes(this.tenantService.getTenantName());
  }

  showAudioAiEntry() {
    // always show AudioAi entry on DEV and QA
    if (environment.production === false && this.tenantService.getTenantName() !== "eon") {
      return true;
    }
    // only show AudioAi entry if tenant is in whitelist
    return this.tenantsWithAudioAi.includes(this.tenantService.getTenantName());
  }

  isCompanyAdministrator() {
    return this.authenticationService.isCompanyAdministrator();
  }
}
